const theme = {
  breakpoints: ['40em', '52em', '64em'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: '"Myriad Pro", sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 400,
    semiBold: 500,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    primary: '#003D79',
    secondary: '#FFB700',
    text: '#2D3033',
    background: '#F0F4F9',
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
  },
  links: {
    primary: {
      py: 2,
      px: 3,
      display: 'inline-block',
      textDecoration: 'none',
      borderRadius: 5,
      color: 'background',
      bg: 'primary',
      fontWeight: 'bold',
      lineHeight: 1.125,
      '&:hover': {
        cursor: 'pointer',
        color: 'primary',
        bg: 'secondary',
      },
    },
    secondary: {
      variant: 'links.primary',
      color: 'primary',
      bg: 'secondary',
      '&:hover': {
        cursor: 'pointer',
        color: 'background',
      },
    },
    outline: {
      variant: 'links.primary',
      color: 'primary',
      bg: 'transparent',
      '&:hover': {
        cursor: 'pointer',
        color: 'secondary',
        textDecoration: 'underline',
        textUnderlineOffset: 4,
        textDecorationThickness: 2,
      },
    },
    nav: {
      p: 3,
      color: 'text',
      textDecoration: 'none',
      transition: '0.2s ease color',
      fontWeight: 'bold',
      position: 'relative',
      ':hover': {
        color: 'secondary',
      },
    },
  },
  buttons: {
    primary: {
      color: 'background',
      bg: 'primary',
      fontWeight: 'bold',
      lineHeight: 1.125,
      '&:hover': {
        cursor: 'pointer',
        color: 'primary',
        bg: 'secondary',
      },
    },
    secondary: {
      variant: 'buttons.primary',
      color: 'primary',
      bg: 'secondary',
      '&:hover': {
        cursor: 'pointer',
        color: 'background',
      },
    },
    outline: {
      variant: 'buttons.primary',
      color: 'primary',
      bg: 'transparent',
      '&:hover': {
        cursor: 'pointer',
        color: 'secondary',
        textDecoration: 'underline',
        textUnderlineOffset: 4,
        textDecorationThickness: 2,
      },
    },
  },
  cards: {
    primary: {
      padding: 2,
      borderRadius: 4,
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
    },
    secondary: {
      variant: 'cards.primary',
      padding: 2,
      borderRadius: 4,
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
      bg: 'secondary',
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: '1px solid',
      borderColor: 'muted',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    h1: {
      variant: 'text.heading',
      fontSize: 5,
    },
    h2: {
      variant: 'text.heading',
      fontSize: 4,
    },
    h3: {
      variant: 'text.heading',
      fontSize: 3,
    },
    h4: {
      variant: 'text.heading',
      fontSize: 2,
    },
    h5: {
      variant: 'text.heading',
      fontSize: 1,
    },
    h6: {
      variant: 'text.heading',
      fontSize: 0,
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
  },
};

export default theme;
